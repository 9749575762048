<template>
  <div class="auth-wrapper auth-v1 px-2 mx-auto col-md-5 register mt-3">
    <div class="auth-inner py-2">
        <h3 class="text-center mb-5">Confirmation de la signature de votre mandat <br>SEPA</h3>
        <h5 class="card-text text-left mt-2 mb-2 text-capitalize">Bienvenue <span class="text-capitalize">{{prenom}}</span> <span class="text-uppercase">{{nom}}</span>,</h5>
        <p class="card-text text-left mb-2 ">Nous avons le plaisir de vous confirmer la signature de votre mandat SEPA 👋.<br>Celui-ci vous a été transmis par mail par notre partenaire Universign.</p>
        <p class="card-text text-left mb-2 ">Nous procéderons au prélèvement de votre 1ère cotisation annuelle, soit 58,80 euros dans un délai minimum de 14 jours.</p>
        <p class="card-text text-left mb-2 ">Nous vous remercions pour votre confiance.</p>
        <p class="card-text text-left mb-5 ">L'équipe BubbleIn.</p>




        <p class="text-right mt-5"><b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="returnBack"
                >
                <span>ACCEDER A BUBBLEIN</span>
                </b-button></p>
    </div>
    <b-overlay :show="showLoading" no-wrap/>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormSelect,
  BFormSelectOption,
  BFormRadioGroup,
  BFormRadio,
  BFormCheckbox,
  BButton,
  BCardText,
  BListGroup,
  BListGroupItem,
  BCard,
  BBadge,
  BImg,
  BOverlay,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BCard,
    BBadge,
    BImg,
    BOverlay
  },
  data() {
    return {
      username:'',
      user_email: '',
      nom: '',
      prenom:'',
      showLoading:true,
    };
  },
  created() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    localize("fr");
    localStorage.setItem("new_user","new_user");
    let user = this.$route.params.id;
    console.log(this.$route.params)
    this.$http.get(`userById/`+user)
            .then((r) => {
              this.username = r.data.username;
              this.user_email = r.data.email;
              this.nom = r.data.nom;
              this.prenom = r.data.prenom;
              this.showLoading = false
            })
            .catch((err) => {
              // console.log(err);
              //this.$router.push({ name: 'home-page' })
            });
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden);
  },
  methods: {
    returnBack(){
        return this.$router.push("/");
    }
    // disabledLinks()
    // {
    //   var links = window.document.querySelectorAll('li a');
    //   console.log(links)
    //   for( let i = 0; i < links.length;i++) {
    //     console.log(links[i]);
    //   }
      
    // }
  },
  // beforeCreate() {
  //   console.log(this.$route.params.id)
  //   if (!this.$route.params.id) {
  //     this.$router.push({ name: "register" });
  //   }
  // },
};
</script>
